import React from 'react'
import { Carousel } from 'react-bootstrap'

export const ImagenCarrusel = ({imagenes}) => {

    if(!imagenes) return null

    if(imagenes.length===0) {
        return null
    }else if(imagenes.length===1){
        const e = imagenes[0]
        return(
            <div className="text-center">
                <img
                    src={`http://167.71.109.215:1337${e.url}`}
                    style={{
                            backgroundRepeat:'cover', 
                            // backgroundSize:'180px', 
                            backgroundPosition:'fixed',
                        }}
                    alt="slide"
                    height="250"
                />
            </div>
        )
    }

    return (
        <Carousel fade>
            {imagenes.map( e => (
                <Carousel.Item className="text-center">
                    <img
                        src={`http://167.71.109.215:1337${e.url}`}
                        alt="slide"
                        height="250"
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}
