import React from 'react'
import { graphql } from 'gatsby'
import Layout from  './layout.js'
import { Container } from 'react-bootstrap';
import { ImagenCarrusel } from './index/imagenCarrusel.js';

export const query = graphql`
    query ($id: String) {
        allStrapiNoticias(
            sort: {order: DESC, fields: strapiId}
            filter: {categoria_noticia: {id: {eq: 1}}, id: {eq: $id}}
        ) {
            nodes {
                id
                nombre
                contenido
                created_at
                strapiId
                imagenes {
                    url
                  }
            }
        }
    }
  
`

const Noticias = ({data: {allStrapiNoticias: { nodes }}}) => {
    const { nombre , contenido , created_at, imagenes } = nodes[0]
    return (
        <Layout>
            <Container className="p-3">
                <ImagenCarrusel imagenes={ imagenes }/>
                <h2 className="text-center mt-3">{ nombre }</h2>
                <p className="mt-3">{ contenido }</p>
                <p className="text-end">{ created_at }</p>
            </Container>
        </Layout>
    )
}

export default Noticias